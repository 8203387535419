define("ember-cp-validations/-private/internal-result-object", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/array", "ember-cp-validations/validations/error", "ember-cp-validations/utils/utils"], function (_exports, _object, _computed, _utils, _array, _error, _utils2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    model: null,
    isValid: true,
    isValidating: false,
    message: null,
    warningMessage: null,
    attribute: '',
    _promise: null,
    _validator: null,
    _type: (0, _computed.readOnly)('_validator._type'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('isAsync')) {
        this._handlePromise();
      }
    },
    isWarning: (0, _computed.readOnly)('_validator.isWarning'),
    isInvalid: (0, _computed.not)('isValid'),
    isNotValidating: (0, _computed.not)('isValidating'),
    isTruelyValid: (0, _computed.and)('isNotValidating', 'isValid'),
    isTruelyInvalid: (0, _computed.and)('isNotValidating', 'isInvalid'),
    isAsync: (0, _object.computed)('_promise', function () {
      return (0, _utils2.isPromise)((0, _object.get)(this, '_promise'));
    }),
    messages: (0, _object.computed)('message', function () {
      return (0, _array.makeArray)((0, _object.get)(this, 'message'));
    }),
    error: (0, _object.computed)('isInvalid', 'type', 'message', 'attribute', function () {
      if ((0, _object.get)(this, 'isInvalid')) {
        return _error.default.create({
          type: (0, _object.get)(this, '_type'),
          message: (0, _object.get)(this, 'message'),
          attribute: (0, _object.get)(this, 'attribute')
        });
      }

      return null;
    }),
    errors: (0, _object.computed)('error', function () {
      return (0, _array.makeArray)((0, _object.get)(this, 'error'));
    }),
    warningMessages: (0, _object.computed)('warningMessage', function () {
      return (0, _array.makeArray)((0, _object.get)(this, 'warningMessage'));
    }),
    warning: (0, _object.computed)('isWarning', 'type', 'warningMessage', 'attribute', function () {
      if ((0, _object.get)(this, 'isWarning') && !(0, _utils.isNone)((0, _object.get)(this, 'warningMessage'))) {
        return _error.default.create({
          type: (0, _object.get)(this, '_type'),
          message: (0, _object.get)(this, 'warningMessage'),
          attribute: (0, _object.get)(this, 'attribute')
        });
      }

      return null;
    }),
    warnings: (0, _object.computed)('warning', function () {
      return (0, _array.makeArray)((0, _object.get)(this, 'warning'));
    }),
    _handlePromise: function _handlePromise() {
      var _this = this;

      (0, _object.set)(this, 'isValidating', true);
      (0, _object.get)(this, '_promise').finally(function () {
        (0, _object.set)(_this, 'isValidating', false);
      });
    }
  });

  _exports.default = _default;
});