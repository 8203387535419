define("ember-leaflet/helpers/ember-leaflet-or", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberLeafletOr = emberLeafletOr;

  function emberLeafletOr(params) {
    return params.reduce(function (previous, current) {
      return previous || current;
    }, undefined);
  }

  var _default = (0, _helper.helper)(emberLeafletOr);

  _exports.default = _default;
});