define('ember-cli-ifa/services/asset-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    enabled: false,
    map: Ember.computed(function () {
      return {};
    }),
    prepend: '/',

    resolve: function resolve(name) {
      var map = Ember.get(this, 'map') || {};
      var prepend = Ember.get(this, 'prepend');
      var enabled = Ember.get(this, 'enabled');
      var assetName = enabled ? map[name] : name;

      return '' + prepend + assetName;
    }
  });
});