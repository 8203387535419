define("ember-composable-helpers/helpers/toggle", ["exports", "@ember/component/helper", "@ember/object", "@ember/utils"], function (_exports, _helper, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toggle = toggle;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function nextIndex(length, currentIdx) {
    if (currentIdx === -1 || currentIdx + 1 === length) {
      return 0;
    }

    return currentIdx + 1;
  }

  function toggle(_ref) {
    var _ref2 = _toArray(_ref),
        prop = _ref2[0],
        obj = _ref2[1],
        values = _ref2.slice(2);

    return function () {
      var currentValue = (0, _object.get)(obj, prop);

      if ((0, _utils.isPresent)(values)) {
        var currentIdx = values.indexOf(currentValue);
        var nextIdx = nextIndex(values.length, currentIdx);
        return (0, _object.set)(obj, prop, values[nextIdx]);
      }

      return (0, _object.set)(obj, prop, !currentValue);
    };
  }

  var _default = (0, _helper.helper)(toggle);

  _exports.default = _default;
});