define("ember-concurrency/helpers/perform", ["exports", "@ember/component/helper", "ember-concurrency/-private/helpers"], function (_exports, _helper, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.performHelper = performHelper;

  function performHelper(args, hash) {
    return (0, _helpers.taskHelperClosure)('perform', 'perform', args, hash);
  }

  var _default = (0, _helper.helper)(performHelper);

  _exports.default = _default;
});