define("ember-cp-validations/utils/get-with-default", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWithDefault;

  function getWithDefault(obj, key, defaultValue) {
    var result = (0, _object.get)(obj, key);

    if (result === undefined) {
      result = defaultValue;
    }

    return result;
  }
});