define("ember-local-storage/adapters/base", ["exports", "@ember/polyfills", "rsvp", "@ember/runloop", "@ember/utils", "@ember/object", "ember-data", "ember-local-storage/mixins/adapters/import-export", "ember-local-storage/helpers/storage", "ember-inflector"], function (_exports, _polyfills, _rsvp, _runloop, _utils, _object, _emberData, _importExport, _storage, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getKeys = Object.keys || _polyfills.keys;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter; // Ember data ships with ember-inflector

  var _default = JSONAPIAdapter.extend(_importExport.default, {
    _debug: false,
    _indices: (0, _object.computed)(function () {
      return {};
    }),
    coalesceFindRequests: false,
    // TODO: v2.0 - What are the defaults now? What versions to support?
    isNewSerializerAPI: true,
    // TODO: v2.0 - Can we deprecate or remove that? What are the defaults now? What versions to support?
    // Reload behavior
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return true;
    },
    generateIdForRecord: function generateIdForRecord() {
      return Math.random().toString(32).slice(2).substr(0, 8);
    },
    // Relationship sugar
    createRecord: function createRecord(store, type, snapshot) {
      snapshot.eachRelationship(function (name, relationship) {
        var kind = relationship.kind,
            options = relationship.options;

        if (kind === 'belongsTo' && options.autoSave) {
          snapshot.record.get(name).then(function (record) {
            if (record) {
              record.save();
            }
          });
        }
      });
      return this._super.apply(this, arguments);
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      snapshot.eachRelationship(function (name, relationship) {
        var kind = relationship.kind,
            options = relationship.options;

        if (kind === 'hasMany' && options.dependent === 'destroy') {
          snapshot.record.get(name).then(function (records) {
            records.forEach(function (record) {
              record.destroyRecord();
            });
          });
        }

        if (kind === 'belongsTo' && options.autoSave) {
          snapshot.record.get(name).then(function (record) {
            if (record) {
              record.save();
            }
          });
        }
      });
      return this._super.apply(this, arguments);
    },
    // Polyfill queryRecord
    queryRecord: function queryRecord(store, type, query) {
      var records = this._super.apply(this, arguments);

      if (!records) {
        var url = this.buildURL(type.modelName, null, null, 'queryRecord', query); // TODO: Document why this is needed or remove it!

        if (this.sortQueryParams) {
          query = this.sortQueryParams(query);
        }

        records = this.ajax(url, 'GET', {
          data: query
        });
      }

      return records.then(function (result) {
        return {
          data: result.data[0] || null
        };
      });
    },
    // TODO: v2.0 - What are the defaults now? What versions to support?
    // Delegate to _handleStorageRequest
    ajax: function ajax() {
      return this._handleStorageRequest.apply(this, arguments);
    },
    // Delegate to _handleStorageRequest
    makeRequest: function makeRequest(request) {
      return this._handleStorageRequest(request.url, request.method, {
        data: request.data
      });
    },
    // Work arround ds-improved-ajax Feature Flag
    _makeRequest: function _makeRequest() {
      return this.makeRequest.apply(this, arguments);
    },
    // Remove the ajax() deprecation warning
    _hasCustomizedAjax: function _hasCustomizedAjax() {
      return false;
    },
    // Delegate to _handle${type}Request
    _handleStorageRequest: function _handleStorageRequest(url, type) {
      var _this = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (this._debug) {
        console.log(url, type, options); // eslint-disable-line no-console
      }

      return new _rsvp.default.Promise(function (resolve, reject) {
        var handler = _this["_handle".concat(type, "Request")];

        if (handler) {
          var data = handler.call(_this, url, options.data);
          (0, _runloop.run)(null, resolve, {
            data: data
          });
        } else {
          (0, _runloop.run)(null, reject, "There is nothing to handle _handle".concat(type, "Request"));
        }
      }, 'DS: LocalStorageAdapter#_handleStorageRequest ' + type + ' to ' + url);
    },
    _handleGETRequest: function _handleGETRequest(url, query) {
      var _this2 = this;

      var _this$_urlParts = this._urlParts(url),
          type = _this$_urlParts.type,
          id = _this$_urlParts.id;

      var storage = (0, _object.get)(this, '_storage');

      var storageKey = this._storageKey(type, id);

      if (id) {
        if (!storage[storageKey]) {
          throw this.handleResponse(404, {}, "Not found", {
            url: url,
            method: 'GET'
          });
        }

        return JSON.parse(storage[storageKey]);
      }

      var records = this._getIndex(type).filter(function (storageKey) {
        return storage[storageKey];
      }).map(function (storageKey) {
        return JSON.parse(storage[storageKey]);
      });

      if (query && query.filter) {
        var serializer = this.store.serializerFor((0, _emberInflector.singularize)(type));
        return records.filter(function (record) {
          return _this2._queryFilter(record, serializer, query.filter);
        });
      }

      return records;
    },
    _handlePOSTRequest: function _handlePOSTRequest(url, record) {
      var _record$data = record.data,
          type = _record$data.type,
          id = _record$data.id;

      var storageKey = this._storageKey(type, id);

      this._addToIndex(type, storageKey);

      (0, _object.get)(this, '_storage')[storageKey] = JSON.stringify(record.data);
      return null;
    },
    _handlePATCHRequest: function _handlePATCHRequest(url, record) {
      var _record$data2 = record.data,
          type = _record$data2.type,
          id = _record$data2.id;

      var storageKey = this._storageKey(type, id);

      this._addToIndex(type, storageKey);

      (0, _object.get)(this, '_storage')[storageKey] = JSON.stringify(record.data);
      return null;
    },
    _handleDELETERequest: function _handleDELETERequest(url) {
      var _this$_urlParts2 = this._urlParts(url),
          type = _this$_urlParts2.type,
          id = _this$_urlParts2.id;

      var storageKey = this._storageKey(type, id);

      this._removeFromIndex(type, storageKey);

      delete (0, _object.get)(this, '_storage')[storageKey];
      return null;
    },
    // TODO: Extract into utility functions in private/query.js
    _queryFilter: function _queryFilter(data, serializer) {
      var _this3 = this;

      var query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var queryType = (0, _utils.typeOf)(query);
      var dataType = (0, _utils.typeOf)(data);

      if (queryType === 'object' && dataType === 'object') {
        return getKeys(query).every(function (key) {
          var queryValue = query[key],
              recordValue; // normalize type

          if (key === 'type' && (0, _utils.typeOf)(queryValue) === 'string') {
            queryValue = (0, _emberInflector.pluralize)(queryValue);
          } // Attributes


          if (key === 'id' || key === 'type') {
            recordValue = data[key];
          } else {
            key = serializer.keyForAttribute(key);
            recordValue = data.attributes ? data.attributes[key] : undefined;
          }

          if (recordValue !== undefined) {
            return _this3._matches(recordValue, queryValue);
          } // Relationships


          key = serializer.keyForRelationship(key);

          if (data.relationships && data.relationships[key]) {
            if ((0, _utils.isEmpty)(data.relationships[key].data)) {
              return;
            }

            return _this3._queryFilter(data.relationships[key].data, serializer, queryValue);
          }
        });
      } else if (queryType === 'array') {
        // belongsTo
        if (dataType === 'object') {
          var queryMessage = query.map(function (item) {
            return getKeys(item).map(function (key) {
              return key + ': ' + item[key];
            });
          }).join(', ');
          throw new Error('You can not provide an array with a belongsTo relation. ' + 'Query: ' + queryMessage); // hasMany
        } else {
          return query.every(function (queryValue) {
            return _this3._queryFilter(data, serializer, queryValue);
          });
        }
      } else {
        // belongsTo
        if (dataType === 'object') {
          return this._matches(data.id, query); // hasMany
        } else {
          return data.some(function (record) {
            return _this3._queryFilter(record, serializer, query);
          });
        }
      }
    },
    _matches: function _matches(recordValue, queryValue) {
      if ((0, _utils.typeOf)(queryValue) === 'regexp') {
        return queryValue.test(recordValue);
      }

      return recordValue === queryValue;
    },
    _urlParts: function _urlParts(url) {
      var parts = url.split('/'); // remove empty part

      parts.shift();
      var type = parts.shift();
      var id = parts.shift();

      if (type === this.modelNamespace) {
        type = "".concat(type, "/").concat(id);
        id = parts.shift();
      }

      return {
        type: type,
        id: id
      };
    },
    _storageKey: function _storageKey(type, id) {
      return (0, _storage._buildKey)(this, type + '-' + id);
    },
    // Should be overwriten
    // Signature: _getIndex(type)
    _getIndex: function _getIndex() {},
    _indexHasKey: function _indexHasKey(type, id) {
      return this._getIndex(type).indexOf(id) !== -1;
    },
    _addToIndex: function _addToIndex(type, id) {
      if (!this._indexHasKey(type, id)) {
        this._getIndex(type).addObject(id);
      }
    },
    _removeFromIndex: function _removeFromIndex(type, id) {
      this._getIndex(type).removeObject(id);
    }
  });

  _exports.default = _default;
});