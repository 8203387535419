define('ember-cli-ifa/utils/get-asset-map-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getAssetMapData;
  function getAssetMapData() {
    // This placeholder is replaced in the build step
    return '__asset_map_placeholder__';
  }
});