define('ember-cli-ifa/initializers/asset-map', ['exports', 'ember-cli-ifa/services/asset-map', 'ember-cli-ifa/utils/get-asset-map-data'], function (exports, _assetMap, _getAssetMapData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    var assetMapFile = (0, _getAssetMapData.default)();

    // This is split out like this, in order to prevent this from being accidentally replaced
    var replacementPath = ['__', 'asset_map_placeholder', '__'].join('');

    if (!assetMapFile || assetMapFile === replacementPath) {
      app.register('service:asset-map', _assetMap.default);
      return;
    }

    if (Ember.typeOf(assetMapFile) === 'object' && assetMapFile.assets) {
      _assetMap.default.reopen({
        map: assetMapFile.assets,
        prepend: assetMapFile.prepend,
        enabled: true
      });
      app.register('service:asset-map', _assetMap.default);
    } else {
      app.deferReadiness();

      var ajax = Ember.$.ajax;

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var options = {
          type: 'GET',
          dataType: 'json',
          success: resolve,
          error: reject
        };
        ajax(assetMapFile, options);
      });

      promise.then(function () {
        var map = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        _assetMap.default.reopen({
          map: map.assets,
          prepend: map.prepend,
          enabled: true
        });
      }).then(function () {
        app.register('service:asset-map', _assetMap.default);
        app.advanceReadiness();
      });
    }
  }

  exports.default = {
    name: 'asset-map',
    initialize: initialize
  };
});