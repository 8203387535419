define("ember-leaflet/helpers/ember-leaflet-eq", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberLeafletEq = emberLeafletEq;

  function emberLeafletEq(params) {
    return params[0] === params[1];
  }

  var _default = (0, _helper.helper)(emberLeafletEq);

  _exports.default = _default;
});