define('ember-cli-framework7/mixins/application-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      // initialize swipeout
      if (this.get('f7InitSwipeout') !== false) {
        this.get('f7').initSwipeout();
      }

      // initialize sortable
      if (this.get('f7InitSortable') !== false) {
        this.get('f7').initSortable();
      }
    }
  });
});