define("ember-cp-validations/validators/dependent", ["exports", "@ember/object", "@ember/debug", "@ember/utils", "@ember/array", "ember-cp-validations/validators/base", "ember-cp-validations/utils/get-with-default"], function (_exports, _object, _debug, _utils, _array, _base, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Defines an attribute as valid only if its dependents are valid.
   *
   *  ## Example
   *
   *  ```javascript
   *  // Full name will only be valid if firstName and lastName are filled in
   *  validator('dependent', {
   *    on: ['firstName', 'lastName']
   *  })
   *  ```
   *
   *  @class Dependent
   *  @module Validators
   *  @extends Base
   */
  var Dependent = _base.default.extend({
    /**
     * @method validate
     * @param {Any} value
     * @param {Object} options
     * @param {Array} options.on Attributes this field is dependent on
     * @param {Object} model
     * @param {String} attribute
     */
    validate: function validate(value, options, model, attribute) {
      var _getProperties = (0, _object.getProperties)(options, ['on', 'allowBlank']),
          on = _getProperties.on,
          allowBlank = _getProperties.allowBlank;

      (false && !((0, _utils.isPresent)(on)) && (0, _debug.assert)("[validator:dependent] [".concat(attribute, "] option 'on' is required"), (0, _utils.isPresent)(on)));

      if ((0, _utils.isNone)(model)) {
        return true;
      }

      if (allowBlank && (0, _utils.isEmpty)(value)) {
        return true;
      }

      var dependentValidations = (0, _getWithDefault.default)(options, 'on', (0, _array.A)()).map(function (dependent) {
        return (0, _object.get)(model, "validations.attrs.".concat(dependent));
      });

      if (!(0, _utils.isEmpty)(dependentValidations.filter(function (v) {
        return (0, _object.get)(v, 'isTruelyInvalid');
      }))) {
        return this.createErrorMessage('invalid', value, options);
      }

      return true;
    }
  });

  Dependent.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      var dependents = (0, _object.get)(options, 'on');
      (false && !((0, _array.isArray)(dependents)) && (0, _debug.assert)("[validator:dependent] [".concat(attribute, "] 'on' must be an array"), (0, _array.isArray)(dependents)));

      if (!(0, _utils.isEmpty)(dependents)) {
        return dependents.map(function (dependent) {
          return "".concat(dependent, ".isTruelyValid");
        });
      }

      return [];
    }
  });
  var _default = Dependent;
  _exports.default = _default;
});